import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    useAddToFavoritesMutation,
    useGetFavoritesOffersQuery,
    useRemoveFromFavoritesMutation
} from "@app/store/offersApi";
import { AppState } from "@app/store/store";
import { SOLD_STATUS_ID } from "@components/Features/Catalog/VerticalCatalogCard/VerticalCatalogCard";
import HoverGallery from "@components/Features/HoverGallery/HoverGallery";
import { CompareToggle } from "@features/compare/CompareToggle";
import HearBtnIcon from "@components/Svg/HearBtnIcon";
import { OfferIcon } from "@features/catalog";
import { ProductPriceUsd } from "@features/product/ProductPriceUsd";
import { useGetCompareOffersQuery } from "@entities/compare";
import { getCurrencySymbol, toRelativeTime } from "@shared/lib/utils";
import Car from "@shared/lib/interfaces/car.interface";
import { numberWithSpaces } from "@src/utils/utils";
import { CatalogCardProps } from "./types";
import classes from "./CatalogCard.module.scss";

const CatalogCard = ({ showFavs = true, banners, ...props }: CatalogCardProps) => {
    const offer = props.data;
    const address = [props.data.city, props.data.address].filter(Boolean).join(", ");
    const offerTitle = offer.mark && offer.model ? `${offer.mark} ${offer.model}` : offer.title;
    const [isArchive, setIsArchive] = useState(false);
    const isAuthorized = useSelector((state: AppState) => state.auth.accessToken);
    const hasToken = typeof isAuthorized === "string" && isAuthorized.length > 0;
    const priceInCurrency = `${numberWithSpaces(props.data.price.toString())} ${getCurrencySymbol(props.data.sourceId)}`;
    // TODO: handle errors
    const { data, error, isLoading, isFetching } = useGetFavoritesOffersQuery(
        {},
        {
            skip: !hasToken
        }
    );
    const { data: compareData } = useGetCompareOffersQuery(undefined, {
        skip: !hasToken
    });

    const [isFavorite, setIsFavorite] = useState(false);
    const [isComparison, setIsComparison] = useState(false);

    useEffect(() => {
        if (data) {
            setIsFavorite(data.some((offer: any) => offer.id === props.data.id));
            setIsArchive(props.data.statusId === SOLD_STATUS_ID);
        }
        if (compareData) {
            setIsComparison(compareData.offers.some((item: Car) => item.id === offer.id));
        }
    }, [data, compareData]);

    const [addToFavorites, result] = useAddToFavoritesMutation();
    const [removeFromFavorite, removeResult] = useRemoveFromFavoritesMutation();
    return (
        <div className={classes.Root}>
            {isArchive && <div className={classes.DisabledText}>Снято с продажи</div>}
            <div
                className={isArchive ? classes.Disabled : ""}
                onClick={(e) => {
                    if (isArchive) {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                }}
            >
                <div
                    className={classes.CatalogCard}
                    onMouseEnter={() => {
                        if (props.setIsHovered) {
                            props.setIsHovered(true);
                        }
                    }}
                    onMouseLeave={() => {
                        if (props.setIsHovered) {
                            props.setIsHovered(false);
                        }
                    }}
                >
                    <div className={classes.CatalogCardContainer}>
                        <div className={classes.ImgContainer}>
                            <Link target="_blank" href={`/product/${offer.id}`}>
                                <HoverGallery
                                    offer={offer}
                                    pics={offer.imagesList}
                                    style={{
                                        width: 300,
                                        height: 200,
                                        borderRadius: 12,
                                        overflow: "hidden"
                                    }}
                                />
                            </Link>
                        </div>

                        <div className={classes.Info}>
                            <div className={classes.TitleContainer}>
                                <Link target="_blank" href={`/product/${offer.id}`}>
                                    {offerTitle}
                                </Link>
                            </div>

                            <div className={classes.Tags}>
                                <div>{offer.year} г</div>
                                <div>{offer.mileage} км</div>
                            </div>

                            <div className={classes.Props}>{offer.prepBodyType}</div>

                            <div className={classes.MoreProps}>
                                <div className={classes.MorePropLabels}>
                                    <label>{offer.prepDriveType}</label>
                                    <label>{offer.prepTransmission}</label>
                                </div>

                                <div className={classes.MorePropVals}>
                                    <div className={classes.MorePropsVal}>{offer.year}</div>
                                    <div className={classes.MorePropsVal}>{offer.prepColor}</div>
                                </div>
                            </div>

                            <div className={classes.Location}>
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="4" cy="4" r="4" fill="#3B85F7" />
                                </svg>
                                {address}
                            </div>
                        </div>

                        <div className={classes.Source}>
                            {props.infoBlockContent && props.infoBlockContent}
                            {!props.infoBlockContent && (
                                <>
                                    <OfferIcon
                                        className={classes.Source_icon}
                                        sourceId={offer.sourceId}
                                    />
                                    {toRelativeTime(offer.createdAt)}
                                </>
                            )}
                        </div>

                        <div className={classes.Price}>
                            <span className={classes.Price__source}>{priceInCurrency}</span>
                            <ProductPriceUsd
                                className={classes.Price__usd}
                                price={offer.price}
                                priceUsd={offer.priceUsd}
                                sourceId={offer.sourceId}
                            />

                            {props.buttonsBlockContent && (
                                <div className={classes.buttonsBlockContent}>
                                    {props.buttonsBlockContent}
                                </div>
                            )}
                        </div>

                        <div className={classes.Buttons}>
                            <CompareToggle
                                className={classes.CompareOffer}
                                classNameIcon={classes.CompareOffer__icon}
                                classNameIconActive={classes["CompareOffer__icon--active"]}
                                offerId={offer.id}
                                isAuth={hasToken}
                            />
                            {showFavs && (
                                <a
                                    className={classes.FavoriteOffer}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (isFavorite) {
                                            removeFromFavorite({
                                                id: offer.id
                                            });
                                            setIsFavorite(!isFavorite);
                                        } else {
                                            addToFavorites({
                                                id: offer.id
                                            });
                                            setIsFavorite(!isFavorite);
                                        }
                                    }}
                                    href=""
                                >
                                    <HearBtnIcon isSelected={isFavorite} />
                                </a>
                            )}
                        </div>

                        {props.button ? (
                            <div className={classes.ButtonCont}>{props.button}</div>
                        ) : null}
                    </div>

                    <div
                        className={`${classes.Divider} ${
                            props.showDivider ? null : classes.HideDivider
                        }`}
                    ></div>
                </div>
            </div>
        </div>
    );
};
export default CatalogCard;