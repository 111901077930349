import Head from "next/head";
import { CustomHeadProps } from "./types";

export const CustomHead = ({
    title,
    description,
    keywords,
    robots,
    canonical,
    openGraph
}: CustomHeadProps) => {
    return (
        <Head>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {canonical && <link rel="canonical" href={canonical} />}
            <meta name="robots" content={robots} />
            {openGraph?.title && <meta property="og:title" content={openGraph.title} />}
            {openGraph?.description && (
                <meta property="og:description" content={openGraph.description} />
            )}
            {openGraph?.url && <meta property="og:url" content={openGraph.url} />}
            {openGraph?.image?.url && <meta property="og:image" content={openGraph.image.url} />}
            {openGraph?.image?.width && (
                <meta property="og:image:width" content={String(openGraph.image.width)} />
            )}
            {openGraph?.image?.height && (
                <meta property="og:image:height" content={String(openGraph.image.height)} />
            )}
            {openGraph?.image?.alt && (
                <meta property="og:image:alt" content={openGraph.image.alt} />
            )}
            {openGraph?.type && <meta property="og:type" content={openGraph.type} />}
            {openGraph?.locale && <meta property="og:locale" content={openGraph.locale} />}
            {openGraph?.siteName && <meta property="og:site_name" content={openGraph.siteName} />}
        </Head>
    );
};